//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'question',

    data() {
        return {
            briefAnswer: ''
        }
    },

    props: {
        briefTitle: String,
        briefQuestion: String,
        briefQuestionDetail: String
    },

    watch: {
        briefAnswer(value) {
            this.$emit('input', value);
        }
    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import question from './question';
import { briefsArr } from '../constants/briefs.const';
import i18n from '../plugins/i18n';
import { required } from "vuelidate/lib/validators";
import * as axios from 'axios';

export default {
    name: 'Briefs',

    components: {
        question
    },

    data() {
        return {
            brief: null,
            briefForm: {
                name: "",
                company: ""
            },
            submitStatus: null,
            briefSent: false,
            briefError: false,
            questionsAndAnswers: {},
        }
    },

    methods: {
        goToHomePage() {
            this.$router.push("/");
        },

        changeLanguage() {
            if (i18n.locale === "ro" && i18n.fallbackLocale === "en") {
                i18n.locale = "en";
                i18n.fallbackLocale = "ro";
            } else if (i18n.locale === "en" && i18n.fallbackLocale === "ro") {
                i18n.locale = "ro";
                i18n.fallbackLocale = "en";
            }
        },

        submit() {
        this.$v.$touch();
    
        if (this.$v.$invalid) {
            this.submitStatus = "BriefFormInvalid";
            window.scrollTo(0,0);
        } else {
            const payload = {
                brief: this.$t(this.brief.title, 'en'),
                name: this.briefForm.name,
                company: this.briefForm.company,
                questionsAndAnswers: Object.keys(this.questionsAndAnswers).map(questionCode => {
                    return {
                        question: this.$t(questionCode, 'en'),
                        answer: this.questionsAndAnswers[questionCode]
                    }
                })
            }
            axios
            .post("/brief.php", payload)
            .then(response => this.briefSent = true)
            .catch(error  => this.briefError = true);
            this.submitStatus = "BriefFormValid";
            }
        },
    },

    mounted() {
        this.brief = briefsArr.find(item => item.id === this.$route.params.briefId);
    },

    validations: {
        briefForm: {
            name: {
                required
            },
            company: {
                required
            }
        }
    }
}

export const briefsArr = [
    {id: 'brandingBrief', title: 'brandingBriefTitle', desc: 'brandingBriefDesc',
        questionsAndDetails: {
            title: ['brandingBrieft1', 'brandingBrieft2', 'brandingBrieft3', 'brandingBrieft4', 'brandingBrieft5'],
            questions: ['brandingBriefq1', 'brandingBriefq2', 'brandingBriefq3', 'brandingBriefq4', 'brandingBriefq5'],
            details: ['brandingBriefd1', 'brandingBriefd2', 'brandingBriefd3', 'brandingBriefd4', 'brandingBriefd5']
        }
    },

    {id: 'socialMediaBrief', title: 'socialMediaBriefTitle', desc: 'socialMediaBriefDesc',
        questionsAndDetails: {
            title: ['socialMediaBrieft1', 'socialMediaBrieft2', 'socialMediaBrieft3', 'socialMediaBrieft4', 'socialMediaBrieft5'],
            questions: ['socialMediaBriefq1', 'socialMediaBriefq2', 'socialMediaBriefq3', 'socialMediaBriefq4', 'socialMediaBriefq5'],
            details: ['socialMediaBriefd1', 'socialMediaBriefd2', 'socialMediaBriefd3', 'socialMediaBriefd4', 'socialMediaBriefd5']
        }
    },

    {id: 'adsBrief', title: 'adsBriefTitle', desc: 'adsBriefDesc',
        questionsAndDetails: {
            title: ['adsBrieft1', 'adsBrieft2', 'adsBrieft3', 'adsBrieft4', 'adsBrieft5', 'adsBrieft6'],
            questions: ['adsBriefq1', 'adsBriefq2', 'adsBriefq3', 'adsBriefq4', 'adsBriefq5', 'adsBriefq6'],
            details: ['adsBriefd1', 'adsBriefd2', 'adsBriefd3', 'adsBriefd4', 'adsBriefd5', 'adsBriefd6']
        }
    },

    {id: 'webDesignBrief', title: 'webDesignBriefTitle', desc: 'webDesignBriefDesc',
        questionsAndDetails: {
            title: ['webDesignBrieft1', 'webDesignBrieft2', 'webDesignBrieft3', 'webDesignBrieft4', 'webDesignBrieft5', 'webDesignBrieft6', 'webDesignBrieft7'],
            questions: ['webDesignBriefq1', 'webDesignBriefq2', 'webDesignBriefq3', 'webDesignBriefq4', 'webDesignBriefq5', 'webDesignBriefq6', 'webDesignBriefq7'],
            details: ['webDesignBriefd1', 'webDesignBriefd2', 'webDesignBriefd3', 'webDesignBriefd4', 'webDesignBriefd5', 'webDesignBriefd6', 'webDesignBriefd7']
        }
    }
]